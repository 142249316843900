const container = { value: false }

export const checkIsHomeToMostRecentEntry = () => {
  const res = container.value
  container.value = false
  return res
}

export const setIsHomeToMostRecentEntry = () => {
  container.value = true
}