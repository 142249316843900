
import { computed, defineComponent, ref } from 'vue'
import ago from 's-ago'

import Levels from '@/components/Visualization/Levels.vue'
import EntryNotes from '@/components/Log/EntryNotes.vue'
import { LevelSet } from '@/models/models'
import {
  checkIsHomeToMostRecentEntry,
  setIsHomeToMostRecentEntry
} from '@/utils/main-to-most-recent-entry'
import { showToast } from '@/components/general/Toasts.vue'
import { dateToString } from '@/utils/date-utils'
import { StoreService, useServiceInjector } from '@/injection'
import { useExtendedRouter } from '@/router/extended-router'

export default defineComponent({
  components: {
    Levels,
    EntryNotes
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useExtendedRouter()

    const inject = useServiceInjector()

    const store = inject(StoreService)

    const levels = ref(new LevelSet(5,5,5,5))
    const whenDate = ref(new Date())
    const when = computed(() => `${dateToString(whenDate.value)} (${ago(whenDate.value)})`)
    const notes = ref('')
    const notFound = ref(false)

    if (checkIsHomeToMostRecentEntry() && store.mostRecentEntrySync.value != null) {
      levels.value = store.mostRecentEntrySync.value.levels
      whenDate.value = store.mostRecentEntrySync.value.datetime
      notes.value = store.mostRecentEntrySync.value.notes
    }
    else {
      store.getEntryById(parseInt(props.id)).then((entry) => {
        levels.value = entry.levels;
        whenDate.value = entry.datetime;
        notes.value = entry.notes;
      }).catch(() => {
        notFound.value = true;
      })
    }

    const deleting = ref(false)
    const waitingToConfirmDelete = ref(false)

    const deleteEntry = () => {
      if (deleting.value) return
      if (waitingToConfirmDelete.value) {
        deleting.value = true;
        store.deleteEntry(parseInt(props.id)).then(() => {
          showToast("Entry deleted")
          router.goBackSafely('/log')
        })
      }
      else {
        waitingToConfirmDelete.value = true
        setTimeout(() => {
          waitingToConfirmDelete.value = false
        }, 3000)
      }
    }

    const devMode = process.env.NODE_ENV === 'development'

    return {
      store,
      levels,
      when,
      notes,
      notFound,
      devMode,
      waitingToConfirmDelete,
      deleting,
      back: () => router.goBackSafely('/', false),
      deleteEntry
    }
  },
  beforeRouteLeave(to) {
    if (to.name === "Home" && !this.deleting) {
      //Going to home
      setIsHomeToMostRecentEntry()
    }
  }
})
