<template>
  <div>
    <template v-if="hasNotes">
      <p class="notes" v-for="para, i in notesParagraphs" :key="i">
        {{para}}
      </p>
    </template>
    <p class="no-notes" v-else>
      This entry has no notes
    </p>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

const newlines = new RegExp('\\n+')

export default defineComponent({
  props: {
    notes: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    return {
      hasNotes: computed(() => !!props.notes),
      notesParagraphs: computed(() => props.notes.split(newlines))
    }
  }
})
</script>

<style>
.no-notes {
  color: var(--white-transparent);
}
.notes {
  margin-top: 0;
}
.notes:not(:last-child) {
  margin-bottom: 16px;
}
.notes:last-child {
  margin-bottom: 6px;
}
</style>