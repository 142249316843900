<template>
  <main id="log-entry-view" :class="notFound? 'two-layer-stack' : 'content two-columns'">
    <teleport to="#title-bar">
      <h1>{{notFound? 'Not Found' : 'Details'}}</h1>
      <button class="text" @click="deleteEntry">
        {{deleting? 'deleting...' : waitingToConfirmDelete? 'confirm' : 'delete'}}
      </button>
    </teleport>
    <div v-if="notFound" class="two-layer-stack">
      <levels class="background levels" v-bind="{spiritual: 5, physical: 5, mental: 5, social: 5}"/>
      <div id="not-found" class="text-align-center foreground">
        <p>Entry not found :(</p>
        <router-link class="button text" to="/">Home</router-link>
      </div>
    </div>
    <template v-else>
      <levels class="levels" v-bind="levels"/>
      <section>
        <entry-notes :notes="notes"/>
        <p>
          <small>
            {{when}}
          </small>
        </p>
        <p v-if="devMode">
          <code>
            <small>
              Entry ID: {{id}}
            </small>
          </code>
        </p>
      </section>
    </template>
    <teleport to="#button-bar">
      <button class="button text" @click="back">Back</button>
      <router-link class="button" :to="{ name: 'EditEntry', params: { id }}">Edit</router-link>
    </teleport>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import ago from 's-ago'

import Levels from '@/components/Visualization/Levels.vue'
import EntryNotes from '@/components/Log/EntryNotes.vue'
import { LevelSet } from '@/models/models'
import {
  checkIsHomeToMostRecentEntry,
  setIsHomeToMostRecentEntry
} from '@/utils/main-to-most-recent-entry'
import { showToast } from '@/components/general/Toasts.vue'
import { dateToString } from '@/utils/date-utils'
import { StoreService, useServiceInjector } from '@/injection'
import { useExtendedRouter } from '@/router/extended-router'

export default defineComponent({
  components: {
    Levels,
    EntryNotes
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useExtendedRouter()

    const inject = useServiceInjector()

    const store = inject(StoreService)

    const levels = ref(new LevelSet(5,5,5,5))
    const whenDate = ref(new Date())
    const when = computed(() => `${dateToString(whenDate.value)} (${ago(whenDate.value)})`)
    const notes = ref('')
    const notFound = ref(false)

    if (checkIsHomeToMostRecentEntry() && store.mostRecentEntrySync.value != null) {
      levels.value = store.mostRecentEntrySync.value.levels
      whenDate.value = store.mostRecentEntrySync.value.datetime
      notes.value = store.mostRecentEntrySync.value.notes
    }
    else {
      store.getEntryById(parseInt(props.id)).then((entry) => {
        levels.value = entry.levels;
        whenDate.value = entry.datetime;
        notes.value = entry.notes;
      }).catch(() => {
        notFound.value = true;
      })
    }

    const deleting = ref(false)
    const waitingToConfirmDelete = ref(false)

    const deleteEntry = () => {
      if (deleting.value) return
      if (waitingToConfirmDelete.value) {
        deleting.value = true;
        store.deleteEntry(parseInt(props.id)).then(() => {
          showToast("Entry deleted")
          router.goBackSafely('/log')
        })
      }
      else {
        waitingToConfirmDelete.value = true
        setTimeout(() => {
          waitingToConfirmDelete.value = false
        }, 3000)
      }
    }

    const devMode = process.env.NODE_ENV === 'development'

    return {
      store,
      levels,
      when,
      notes,
      notFound,
      devMode,
      waitingToConfirmDelete,
      deleting,
      back: () => router.goBackSafely('/', false),
      deleteEntry
    }
  },
  beforeRouteLeave(to) {
    if (to.name === "Home" && !this.deleting) {
      //Going to home
      setIsHomeToMostRecentEntry()
    }
  }
})
</script>

<style>
.levels {
  height: var(--base-viz-size);
  width: var(--base-viz-size);
  max-height: var(--base-viz-max-size);
  max-width: var(--base-viz-max-size);
  margin: 20px auto;
}
#not-found p {
  font-size: 2rem;
}
</style>