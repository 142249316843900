
import { computed, defineComponent } from 'vue'

const newlines = new RegExp('\\n+')

export default defineComponent({
  props: {
    notes: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    return {
      hasNotes: computed(() => !!props.notes),
      notesParagraphs: computed(() => props.notes.split(newlines))
    }
  }
})
